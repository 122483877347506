export const ADD_RECIPE = 'ADD_RECIPE'
export const REMOVE_RECIPE = 'REMOVE_RECIPE'
export const SET_RECIPES = 'SET_RECIPES'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const ADD_FILE = 'ADD_FILE'
export const CLEAR_UPLOADED_FILES = 'CLEAR_UPLOADED_FILES'
export const UPDATE_SEARCH_TERMS = 'UPDATE_SEARCH_TERMS'
export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART'
export const CHANGE_CART_ITEM_SERVING_SIZE = 'CHANGE_CART_ITEM_SERVING_SIZE'
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART'
export const TOGGLE_CART_INGREDIENT = 'TOGGLE_CART_INGREDIENT'
export const CLEAR_CART = 'CLEAR_CART'
export const SET_USER = 'SET_USER'
export const SNACK_BAR = 'SNACK_BAR'
